$(function(){
	$('.more-item').click(function(event) {
		var list = $(this).parent().parent().attr( "id");

		var clicks = $('#' + list).find('.more-item').data('clicks');
		if (clicks) {
			$('#' + list).find('.link-hide' ).show();
			$(this).html('Скрыть');
		} else {
			$('#' + list).find('.link-hide' ).hide();
			$(this).html('Смотреть все');
		}

		$(this).data("clicks", !clicks);
	});
});



$('#more-card').click(function() {
	var clicks = $(this).data('clicks');

	if (clicks) {
		$( ".card-hide" ).show();
		$(this).html('Скрыть');
	} else {
		$('.card-hide').hide();
		$(this).html('Смотреть все');
	}

	$(this).data("clicks", !clicks);
});

$('.more').click(function() {
	var clicks = $(this).data('clicks');
	console.log(clicks);

	if (clicks) {
		$( ".more-hide" ).show(400,"linear");
		$(this).html('Скрыть');
	} else {
		$('.more-hide').hide(400,"linear");
		$(this).html('Подробнее');
	}

	$(this).data("clicks", !clicks);
});



$('#tourDatepicker, #formDatepicker').datepicker({
	clearBtn: true,
	language: "ru",
	minDate: new Date(),
	maxDate: "+1Y",
	startDate: new Date(),
	orientation: "bottom left",
	todayHighlight: true,
	daysOfWeekDisabled: [1, 6],
	autoclose: true,
	multidate: true,
});



$(function(){

	$('#slide-submenu').on('click',function() {			        
		$(this).closest('.list-group').fadeOut('slide',function(){
			$('.mini-submenu').fadeIn();	
		});

	});

	$('.mini-submenu').on('click',function(){		
		$(this).next('.list-group').toggle('slide');
		$('.mini-submenu').hide();
	})
});


$(".info").html($(".info").text().replace(/([\s\S]{110})([\s\S]*)/im, "$1<span>...</span><span class='hidden'>$2</span>"));


